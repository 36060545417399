import type {NextPage} from 'next'
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {NavBarLanding} from "../components/navBars/NavBarLanding";
import {LandingView} from "../components/landing/LandingView";
import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import {AuthService} from "../services/authService";
import {useAppSelector} from "../app/hooks";
import {selectPrimaryLocation} from "../redux/user/userSlice";
import {selectIsAuthenticatingSession} from "../redux/auth/authSlice";
import {LoadingSpinnerIcon} from "../components/icons";
import {useDeeplinkRouter} from "../utils/useDeeplinkRouter";
import {ShopLandingView} from "../components/landing/ShopLandingView";

const Landing: NextPage = () => {
  const router = useRouter()

  const primaryLocation = useAppSelector(selectPrimaryLocation);
  const isAuthenticatingSession = useAppSelector(selectIsAuthenticatingSession);
  const [didLoad, setDidLoad] = useState(false)
  const { isHandlingSubject } = useDeeplinkRouter()
  const subdomain = process.env.NEXT_PUBLIC_SUBDOMAIN || window.location.host.split('.')[0]

  useEffect(() => {
    if (primaryLocation && !didLoad && !isHandlingSubject.getValue()) {
      router.push('/store-feed')
    }
  }, [primaryLocation, didLoad, router])

  useEffect(() => {
    if (!isAuthenticatingSession) {
      setDidLoad(true)
    }
  }, [isAuthenticatingSession])

  if (isAuthenticatingSession && AuthService.isRefreshTokenValid()) {
    return <div className="flex flex-col h-screen items-center justify-center">
      <LoadingSpinnerIcon className="h-10 w-10 animate-spin text-fg-primary" />
    </div>
  }

  return <div className="flex flex-col overflow-x-hidden">
    <NavBarLanding />
    {subdomain?.includes('shop') ? <ShopLandingView /> : <LandingView/>}
  </div>
}

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...await serverSideTranslations(locale, ['common', 'landing', 'auth', 'location', 'store', 'payment', 'checkout', 'order']),
  },
})

export default Landing
